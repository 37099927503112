<template>
  <b-checkbox
    :value="values[field.id]"
    class="column is-12 toc-checkbox"
    size="is-small"
    :native-value="field.id"
    @input="handleInput"
    v-on="fieldEvents"
  >
    <p v-html="field.properties.basic.text" />
  </b-checkbox>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const handleInput = value => {
    emit('update', {
      value
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  return {
    handleInput
  };
};
export default __sfc_main;
</script>
